@import url(../style/normalize.css);
@import "./_variables.scss";
@import "./modules/hero.scss";
@import "./modules/header.scss";

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}


body {
    background-color: $background;
    margin: 0 auto;
    height: 100vh;
    padding: 0;
    font-family: $Roboto;
    color: $mainFont;
    // display: flex;
    // justify-content: center;
    // align-items: center;

}

.main {
    margin: 0 80px;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}