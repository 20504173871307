@import "../_variables.scss";

.hero {
    &__description {
        color: $secondaryFont;
    }
    &__welcome {
        font-size: 140px;
        line-height: 90px;
        font-weight: 900;
        margin: 60px 0;
    }
    &__info {
        font-size: 1.2em;
    }
}
.contact {
    &__mail {
        position: relative;
        color: $mainFont;
        opacity: 1;
        font-size: 1.2em;
        font-weight: bold;
        text-decoration: none;
        /* font-weight: bold; */
        transition: color 0.3s;
        -webkit-transition: all 0.2s ease-in;
        -moz-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }
    &__mail::after {
        content: "";
        width: 0;
        height: 2px;
        font-weight: bold;
        background-color: $mainFont;
        position: absolute;
       margin-top: 3px;
        top: 100%;
        left: 0;
        bottom: 3px;
        transition: width 0.3s;
    }
    &__mail:hover::after {
        width: 100%;
    }
}

.media {
    &__list {
        margin-top: 40px;
        padding: 0;
    }
    &__list--item {
        display: inline;
        padding-right: 20px;
        
    }
    &__list--item a {
        text-decoration: none;
        opacity: 0.8;
        
    }
    &__list--item a:hover {
        opacity: 1;
    }

    &__icon {
        
    }
}

.photo {
    display: flex;
    align-items: flex-end;
    justify-content: end;
    &__hero {
        width: 400px;
        animation: heroAnimation 80s linear infinite;
    }
}


@keyframes heroAnimation {
    0% {
        border-radius: 30% 70% 70% 30%/40% 40% 60% 60%
    }
    20% {
        border-radius: 70% 30% 30% 70%/60% 60% 40% 40%
    }
    40% {
        border-radius: 30% 70% 30% 70%/40% 60% 40% 60%
    }
    60% {
        border-radius: 30% 70% 70% 30%/40% 40% 60% 60%
    }
    80% {
        border-radius: 70% 30% 30% 70%/60% 60% 40% 40%
    }
    100% {
        border-radius: 30% 70% 70% 30%/40% 40% 60% 60%
    }
}

@media (max-width: 1024px) and (orientation: portrait) {
   
    .photo {
    display: none;

    }
} 

@media (max-width: 414px) and (orientation: portrait) {
   
    .hero {
        &__welcome {
        font-size: 80px;
        line-height: 50px;
        }
    }
    .photo {
    display: none;

    }
} 
