@import "../_variables.scss";
.header {
    position: absolute;
    width: 100vw;
    font-family: $Roboto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 80px;
    &__title {
        font-size: 1.5em;
        color: $mainFont;
    }
    &__links {

    }
}

